/*FONT DECLARATIONS*/
@font-face {
  font-family: 'Messina Sans';
  src: url('../../assets/font/MessinaSans-Bold.woff2') format('woff2'),
       url('../../assets/font/MessinaSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('../../assets/font/MessinaSans-Regular.woff2') format('woff2'),
       url('../../assets/font/MessinaSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('../../assets/font/MessinaSans-Light.woff2') format('woff2'),
       url('../../assets/font/MessinaSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neusa Next Pro';
  src: url('../../assets/font/NeusaNextPro-Bold.woff2') format('woff2'),
       url('../../assets/font/NeusaNextPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Neusa Next Pro';
  src: url('../../assets/font/NeusaNextPro-Regular.woff2') format('woff2'),
       url('../../assets/font/NeusaNextPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Neusa Next Pro';
  src: url('../../assets/font/NeusaNextPro-Light.woff2') format('woff2'),
       url('../../assets/font/NeusaNextPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
/**/

/*VIEW ANIMATIONS*/
.fade-enter {
  opacity: 0;
  z-index: 1;
  transition: opacity 400ms ease;
}
.fade-enter.fade-enter-active {
  opacity: 1;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease;
}
.fade-exit-done {
  opacity: 0;
}
/**/

/* PLAYER CAROUSEL */
.carousel-root {
  height: 100%;
}

.carousel-slider {
  display: flex;
  height: 100%;
}

/*LOCK*/
.lock.day .react-pattern-lock__point-inner {
  background: rgba(0, 0, 0, 0.4);
}
.lock.day .react-pattern-lock__connector {
  background: rgba(0, 0, 0, 0.2);
}
.lock.day
  .react-pattern-lock__pattern-wrapper.success
  .react-pattern-lock__point-inner {
  background: rgba(0, 0, 0, 0.7);
}
.lock.day
  .react-pattern-lock__pattern-wrapper.success
  .react-pattern-lock__connector {
  background: rgba(0, 0, 0, 0.6);
}

.lock.night .react-pattern-lock__point-inner {
  background: rgba(255, 255, 255, 0.4);
}
.lock.night .react-pattern-lock__connector {
  background: rgba(255, 255, 255, 0.2);
}
.lock.night
  .react-pattern-lock__pattern-wrapper.success
  .react-pattern-lock__point-inner {
  background: rgba(255, 255, 255, 0.8);
}
.lock.night
  .react-pattern-lock__pattern-wrapper.success
  .react-pattern-lock__connector {
  background: rgba(255, 255, 255, 0.6);
}

.react-pattern-lock__pattern-wrapper.error .react-pattern-lock__point-inner,
.react-pattern-lock__pattern-wrapper.error .react-pattern-lock__connector {
  background: #940000 !important;
}

.demo-lock {
  transform: scale(0.8);
  margin: -40px 0px;
}
.react-pattern-lock__point-inner.active:before,
.demo-lock
  .react-pattern-lock__point-wrapper[data-index="0"]
  .react-pattern-lock__point-inner:before,
.demo-lock
  .react-pattern-lock__point-wrapper[data-index="3"]
  .react-pattern-lock__point-inner:before,
.demo-lock
  .react-pattern-lock__point-wrapper[data-index="6"]
  .react-pattern-lock__point-inner:before,
.demo-lock
  .react-pattern-lock__point-wrapper[data-index="7"]
  .react-pattern-lock__point-inner:before,
.demo-lock
  .react-pattern-lock__point-wrapper[data-index="8"]
  .react-pattern-lock__point-inner:before {
  content: "";
  width: 30px;
  height: 30px;
  display: block;
  border: 2px solid #32fd43;
  border-radius: 50%;
  position: absolute;
  margin-left: -5px;
  margin-top: -5px;
}

/*ADMIN PANEL*/
.admin-button {
  display: inline-block;
  padding: 0.5em 3em;
  margin:0 0.3em 0.3em 0;
  box-sizing: border-box;
  text-decoration:none;
  text-transform:uppercase;
  font-weight:400;
  text-align:center;
  transition: all 0.15s;
  width: 80%;
}
/**/

/* prevent pull-down-to-refresh of mobile chrome */
body.no-pull-refresh {
  overscroll-behavior-y: contain;
}

div.slider-wrapper, ul.slider.animated {
  height: 100%;
}